html, body{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body{
    height: 100vh;
    width: 100vw;
}

#root{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}